.MainDash {
    /* padding-top: 1rem; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: absolute;
    left: 50%;
    transform: translateX(-40%);
    width: 70%;
    // text-align: center;
    margin: 40px 0;
    font-family: 'Poppins', sans-serif;
}
.notify {
    position: fixed;
    /* left: 10%; */
    /* top: 1vh; */
    top: 25vh;
    left: 10% !important;
    /* width: 93%; */
    width: fit-content;
    height: auto;
    background-color: white;
    color: black;
    z-index: 1000;
    border-radius: 20px;
    font-family: 'Poppins', sans-serif;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: 0px 0px 20px 2000px rgba(0, 0, 0, 0.767);
    overflow-y: auto;
}
.notify > p {
    margin: 0 20px;
}
.MainDash-closed {
    /* padding-top: 1rem; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: absolute;
    left: 44%;
    transform: translateX(-40%);
    width: 85%;
    // text-align: center;
    margin: 40px 0;
    font-family: 'Poppins', sans-serif;
}
.iniziale-top-dash{
    width: 30px;
    height: 30px;
    max-width: 30px;
    max-height: 30px;
    border-radius: 50%;
    background-color: #3471CC;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}
.leggenda-visibile{
    width: 40%;
    position: fixed;
    background-color: #ffffff;
    border-radius: 10px;
    height: 95vh;
    max-height: 95vh;
    top: 20px;
    z-index: 1000;
    padding-top: 0;
    left: 6%;
    transform: translateY(0%);
    transition: 0.3s all;
}
.btn-legenda-visibile{
    cursor: pointer;
    border: none;
    background-color: #3471CC;
    padding: 6px 16px;
    color: white;
    margin-left: 50px;
    border-radius: 10px;
}
.leggenda-slide-cont{
    width: 40%;
    position: fixed;
    background-color: #ffffff;
    border-radius: 10px;
    height: 95vh;
    max-height: 95vh;
    top: 0px;
    z-index: 0;
    padding-top: 0;
    left: 6%;
    transform: translateY(-150%);
    transition: 0.3s all;
}
.legenda-container{
    width: 100%;
    padding-top: 0;
    max-height: 100%;
    overflow-y: scroll;
}
.legenda-container::-webkit-scrollbar {
    width: 5px;
  }
  
  .legenda-container::-webkit-scrollbar-thumb {
    background-color: #3471CC;
  }
  
  .legenda-container::-webkit-scrollbar-track {
    background-color: #ffffff; /* Colore del track (sfondo) */
  }
.leggenda-top{
    background-color: #3471CC;
    color: white;
    margin-top: 0;
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    position: sticky;
    padding-top: 10px;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-dentro-legenda{
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-dentro-legenda > button {
    border-radius: 5px;
    border: none;
    background-color: #3471CC;
    color: white;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    padding: 3px 16px;
}
.leggenda-top > .indietro-icona {
    position: absolute;
    left: 20px;
    top: 10px;
}
.esiti-list{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-height: 90%;
    margin-top: 20px;
    margin-bottom: 90px;
}
.esiti-item{
    border-bottom: 1px solid #18181880;
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0;
}
.esiti-item > span {
    width: 40px;
    min-width: 40px;
    min-height: 20px;
    height: 20px;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 4px;
    border-radius: 30px;
    background-color: #000000;
}
.esiti-item:nth-of-type(1) > span{
    background-color: #94B7D3;
    color: #94B7D3;  
}
.esiti-item:nth-of-type(2) > span{
    background-color: #426487;
    color: #426487;  
}
.esiti-item:nth-of-type(3) > span{
    background-color: #F88600;
    color: #F88600;  
}
.esiti-item:nth-of-type(4) > span{
    background-color: #C41247;
    color: #C41247;  
}
.esiti-item:nth-of-type(5) > span{
    background-color: #263F45;
    color: #263F45;  
}
.esiti-item:nth-of-type(6) > span{
    background-color: #852F1F;
    color: #852F1F;  
}
.esiti-item:nth-of-type(7) > span{
    background-color: #FBBC05;
    color: #FBBC05;  
}
.esiti-item:nth-of-type(8) > span{
    background-color: #3471CC;
    color: #3471CC;  
}
.esiti-item:nth-of-type(9) > span{
    background-color: #30978B;
    color: #30978B;  
}
.esiti-item:nth-of-type(10) > span{
    background-color: #00F0B6;
    color: #00F0B6;  
}
.esiti-item > div {
    margin-left: 10px;
}
.esiti-item > div > p:first-of-type{
    font-size: 16px;
    font-weight: 600;
}
.esiti-item > div > p:last-of-type{
    font-size: 12px;
    color: #00000066;
    margin-top: -5px;
}
.MainDash-abbonamento {
    margin: 0 auto;
    width: 80%;
    text-align: center;
}

.topDash {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 10rem;
    margin-bottom: 5px;
    margin-top: 20px;

    &.exportright {
        justify-content: flex-end;
    }
}



.topDash-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    padding: 0 10px;
}

.topDash-item #dashcerca {
    font-size: 16px;
    background-color: transparent;
}

.topDash-item #emoji {
    font-size: 24px;
}

.topDash-item a {
    font-size: 18px;
    font-weight: 300;
    text-decoration-thickness: 2px;
}

.topDash-item p {
    margin: 0;
}

.topDash-item.exportlink {
    align-items: center;
    gap: 1rem;
}

.topDash-item label.hideexport {
    width: 40%;
    background-color: white;
    border-radius: 10px;
}


.analitic-container {
    background: #FFFFFF;
    box-shadow: 0px 11px 24px rgba(0, 0, 0, 0.07);
    border-radius: 40px;
    display: flex;
    flex-direction: row;
    gap: 6rem;
    justify-content: center;
    align-items: center;
    height: 180px;
}

.analitic-item {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.analitic-item:nth-of-type(1),
.analitic-item:nth-of-type(2) {
    border-right: 1px solid #0000008a;
    padding-right: 30px;
}

.item1 {
    width: 130px;
    height: 130px;
    background: rgba(48, 151, 139, 0.3);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item1 img {
    height: 50px;
}

.item2 p {
    margin: 0;
}

.item2>p:first-of-type {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 0px;
    color: #00000091
}

.item2>p:nth-of-type(2) {
    font-size: 50px;
    font-weight: 600;
    margin: 10px 0;
}

.item2>p:nth-of-type(3) {
    font-size: 20px;
    font-weight: 300;
    color: #00000091;
    line-height: 0px;
}

#looptopdash {
    scale: 1.5;
    color: #00000080;
}


#lasttopdashitem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font-size: 35px;
    color: grey;
}

#lasttopdashitem u {
    color: black;
}

#lasttopdashitem #fstdiv {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

#lasttopdashitem #dwrep {
    margin-top: 0px;
    margin-right: 30px;
    border: 1px solid black;
    padding: 6px 16px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
}

#lasttopdashitem #dwrep a {
    color: rgba(0, 0, 0, 0.905);
    font-size: 18px;
    font-weight: 400;
    margin-right: 10px;
}

#lasttopdashitem #dwrep svg {
    height: 20px;
    fill: rgba(0, 0, 0, 0.4);
}

.dashhideexport {
    align-items: flex-start;
    margin-top: 20px;
    justify-content: space-between;
}

#fstdashitem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#fstdashitem #attiviora {
    margin-top: 20px;
}

#fstdashitem #attiviora .tit {
    font-size: 25px;
    color: rgba(0, 0, 0, 0.4);
    position: relative;
}

#fstdashitem #attiviora .tit::after {
    content: "";
    height: 15px;
    width: 15px;
    position: absolute;
    border-radius: 100%;
    background-color: #30978B;
    top: 7px;
    transform: translateX(3px);
}

#fstdashitem #attiviora .titnum {
    font-size: 50px;
}

#fstdashitem #attiviora .circles {
    color: rgba(0, 0, 0, 0.4);
}

#fstdiv {
    p {
        white-space: nowrap;
    }
}

@media screen and (max-width: 1200px) {
    .MainDash {
        justify-content: flex-start;
        margin-top: 2rem;
    }
}

@media screen and (max-width: 768px) {
    .MainDash {
        align-items: center;
    }
}

@media screen and (max-width:500px) {
    .MainDash {
        width: 100% !important;
        position: static;
        left: 0;
        transform: none;
        text-align: center;
    }

    .topDash {
        width: 100%;
        gap: 0rem;
        margin: 0 auto;
    }

    .topDash>.topDash-item:nth-of-type(1) {
        display: none;
    }

    .topDash-item {
        font-size: 0.8rem;
    }

    .topDash-item:nth-of-type(3)>p {
        color: #00000082;
        font-size: 14px;
    }

    .topDash-item:nth-of-type(3)>p>span {
        color: #000000;
        font-size: 14px;
    }

    .analitic-container {
        gap: 0rem;
    }

    .table-main-mobile {
        width: 90% !important;
        margin-top: 0px;
    }
}
.Table-admin {
    margin-top: 20px;

    .filtralead {
        width: 100%;
        margin-bottom: 10px;
        text-align: start;

        .wrapperwrapper {
            display: flex;
            justify-content: flex-start;
            gap: 2rem;
            align-items: flex-start;
            margin-top: 0px;

            .wrapper {
                display: flex;
                gap: 3rem;
                align-items: center;
                width: 100%;
            }

            .leadslinks {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 3rem;
                width: 100%;

                button,
                a {
                    background-color: #3471CC;
                    border: none;
                    outline: none;
                    font-size: 14px;
                    font-weight: 500;
                    color: white;
                    padding: 10px;
                    border-radius: 10px;
                    box-shadow: 0px 7px 12px rgba(52, 113, 204, 0.2);
                    white-space: nowrap;

                    svg {
                        fill: white;
                        margin-right: 10px;
                    }

                    &:hover {
                        scale: 1.02;
                    }
                }

                a {
                    color: #3471CC;
                    background-color: white;
                    text-decoration: none;
                }

                #visualbtt {
                    background-color: transparent;
                    color: #3471CC;
                    text-decoration: underline;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    box-shadow: none;
                    gap: 1rem;
                }
            }
            .secondLink{
                justify-content: flex-start;
            }
        }
    }
    .filtralead label {
        color: black;
        font-weight: 600;
        text-decoration: underline;
        font-size: 20px;
    }


    .filtralead input {
        background-color: var(--bg);
        border: none;
        outline: none;
        color: #3471CC;
        font-size: 20px;
        text-align: end;
        width: fit-content;
        position: relative;
    }

    .filtralead input[type="date"]::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }


    .sectionswrapper {
        display: flex;
        gap: 2rem;
        height: fit-content;
        justify-content: flex-start;
        align-items: flex-start;
        overflow-x: auto;
        padding: 10px 40px;
        transform: rotateX(180deg);
        padding-bottom: 30px;
        margin-top: 30px;

        &::-webkit-scrollbar {
            width: 12px;
            height: 10px;
        }

        &::-webkit-scrollbar-track {
            background: rgba(52, 113, 204, 0.1);
            border-radius: 70px;
            padding: 4px 8px;
            width: 20px;
        }

        &::-webkit-scrollbar-thumb {
            background: #3471CC;
            border-radius: 56px;
            width: 12px;
            position: absolute;
            top: 0;
        }


        .secwrap {
            width: calc(1/6 * 100%);
            // min-width: 110px;
            min-width: 220px;
            border-top: 5px solid transparent;
            transform: rotateX(180deg);


            &.colordroprow {
                border-radius: 4px;
                background-color: rgba(91, 104, 109, 0.048);
            }


            &:nth-of-type(1) {
                border-color: #94B7D3;
              }
            
              &:nth-of-type(2) {
                border-color: #426487;
              }
            
              &:nth-of-type(3) {
                border-color: #F88600;
              }
            
              &:nth-of-type(4) {
                border-color: #C41247;
              }
            
              &:nth-of-type(5) {
                border-color: #263F45;
              }
            
              &:nth-of-type(6) {
                border-color: #852F1F;
              }
            
              &:nth-of-type(7) {
                border-color: #FBBC05;
              }
            
              &:nth-of-type(8) {
                border-color: #3471CC;
              }
              
              &:nth-of-type(9) {
                border-color: #30978B;
              }
            
              &:nth-of-type(10) {
                border-color: #00F0B6; 
              }

            .secheader {
                background-color: white;
                padding: 15px;
                border-radius: 0 0 10px 10px;
                box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.15);
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                position: relative;

                &::after {
                    content: ">";
                    position: absolute;
                    transform: rotate(90deg);
                    bottom: -2px;
                    left: auto;
                    font-size: 15pt;
                    color: #3471CC;
                    font-weight: 500;
                }

                &:hover {
                    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);

                }

                span {
                    font-size: 16px;
                    font-weight: 400;
                }

                span:nth-child(2) {
                    font-size: 12px;
                    border: 1px solid;
                    height: 30px;
                    width: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100%;
                }

                &.grabbing {
                    animation: pulseheader 1.5s ease-in-out 0s infinite;
                }

                @keyframes pulseheader {
                    0% {
                        transform: scale(1);
                        opacity: 1;
                    }

                    50% {
                        transform: scale(1.02);
                        opacity: 0.2;
                    }

                    100% {
                        transform: scale(1);
                        opacity: 1;
                    }
                }

            }

            .entries {
                height: 60vh;
                overflow-y: auto;
                margin-top: 15px;



                &::-webkit-scrollbar {
                    width: 8px;
                }

                &::-webkit-scrollbar-track {
                    background: rgba(52, 113, 204, 0.1);
                    border-radius: 70px;
                    padding: 4px 8px;
                    width: 20px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #3471CC;
                    border-radius: 56px;
                    width: 12px;
                }


            }
        }

    }

}

@media screen and (width >= 2001px) {
    .topDash {
        gap: 5rem !important;
        margin-bottom: 30px;
        margin-top: 20px;
    }

    .entries {
        height: 50vh !important;
    }
}


@media screen and (width <=2000px) {
    .topDash {
        gap: 5rem !important;
        margin-bottom: 30px;
        margin-top: 15px;
    }

    .entries {
        height: 50vh !important;
    }
}

@media screen and (width <=1600px) {
    .topDash {
        gap: 1rem !important;
        margin-bottom: 20px;
        margin-top: 15px;
        #dashcerca {
            font-size: 18px !important;
        }

    }

    .Table-admin {
        margin-top: 0px !important;
    }

    .topDash .exportlink {
        flex-direction: column-reverse !important;
        gap: 0 !important;

        a {
            font-size: 18px !important;
        }
    }

    #lasttopdashitem {
        #emoji {
            font-size: 25px !important;
        }

        font-size: 18px !important;
    }

    .Table-admin {
        .filtralead {
            * {
                font-size: 15px !important;
            }
        }
    }
}

@media screen and (width <=2000px) {
    .Table-admin {
        margin-top: 5px !important;
    }
    #dwrep {
        margin-top: 0;
        border: 1px solid black;
        padding: 7px 16px;
        border-radius: 5px;
        a {
            margin-top: 0px !important;
            font-size: 20px !important;
        }
        svg{
            height: 20px;
        }
    }
}
@media screen and (width <=2500px) {
    .Table-admin {
        margin-top: 5px !important;
    }
    #fstdiv {
        p,
        #emoji {
            font-size: 20px !important;
        }
    }
    #dwrep {
        margin-top: 0;
        a {
            margin-top: 0px !important;
            font-size: 20px !important;
        }
        svg{
            height: 20px;
        }
    }
}


@media screen and (width <=1200px) {

    .Table-admin {
        margin-top: 0px !important;
    }

    .entries {
        height: 40vh !important;
    }

    .topDash {

        #fstdashitem {
            padding-left: 0 !important;
            transform: scale(0.8);
        }

        .exportlink {
            padding: 0 15px !important;
            white-space: nowrap;

            svg {
                height: 18px !important;
            }

            a {
                font-size: 14px !important;
            }
        }

        #dashcerca {
            font-size: 15px;
        }

        #looptopdash {
            scale: 1.6;
        }

        #lasttopdashitem {
            #fstdiv {

                p,
                #emoji {
                    font-size: 18px !important;
                }
            }

            #dwrep {
                margin: 0 !important;
                margin-top: 10px !important;
                text-align: center;

                a {
                    margin: 0 !important;
                    white-space: nowrap;
                    font-size: 18px !important;
                }

                svg {
                    height: 15px !important;
                }
            }
        }
    }

    .topDash.dashhideexport {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .topDash {
        gap: 0 !important;
    }

    .topDash-item label.hideexport {
        width: 300px;
    }

    #lasttopdashitem {
        font-size: 18px;

        a {
            font-size: 18px !important;
        }

    }

    #attiviora {
        margin-top: 10px !important;

        .tit {
            font-size: 20px !important;
        }

        .titnum {
            font-size: 30px !important;
        }
    }

    #lasttopdashitem {
        font-size: 18px !important;
    }

    .MainDash {
        transform: translateX(-44%) !important;
    }

    .filtralead {
        .wrapperwrapper {
            flex-direction: column !important;
            gap: 1rem !important;

            .leadslinks {
                gap: 1rem !important;
            }

        }
    }


}

@media screen and (width <=1000px) {

    .topDash {
        display: grid !important;
        grid-template-columns: repeat(10, 1fr);

        .topDash-item {
            padding: 0 !important;
        }

        .topDash-item:nth-of-type(1) {
            grid-column: span 3;
        }

        .topDash-item:nth-of-type(2) {
            grid-column: span 5;
        }

        .topDash-item:nth-of-type(3) {
            grid-column: span 2;
        }

        #lasttopdashitem {
            font-size: 18px !important;
        }
    }

    .dashhideexport {
        display: flex !important;
        justify-content: space-between !important;
    }

    .MainDash {
        width: 80% !important;
        transform: translateX(-46%) !important;
    }

    .leadslinks {
        gap: 1rem !important;
        flex-direction: column !important;
        margin: 20px 0 !important;

        a,
        button {
            font-size: 16px !important;
        }
    }

}


@media screen and (width <=800px) {
    .topDash {
        display: flex !important;
        flex-wrap: wrap !important;
        justify-content: space-between !important;
        align-items: center !important;

        .topDash-item:nth-of-type(1) {
            width: 100% !important;
            transform: unset !important;
            transform: translateX(10px) !important;
        }

        .topDash-item:nth-of-type(2) {
            width: 50% !important;
            align-items: unset !important;
            transform: translateX(10px) !important;
            flex-direction: row !important;
            justify-content: flex-start !important;
            gap: 0.3rem !important;
        }

        #lasttopdashitem {
            font-size: 22px !important;
        }
    }

    .dashhideexport {
        .topDash-item:nth-of-type(1) {
            width: auto !important;
            transform: unset !important;
            transform: translateX(10px) !important;
        }

        .topDash-item:nth-of-type(1) .hideexport {
            width: 200px !important;
        }
    }



}

@media screen and (width <=1800px) {
    .secheader {
        span {
            font-size: 11px !important;
        }

        span:nth-child(2) {
            font-size: 8px;
            height: 20px !important;
            width: auto !important;
            padding: 3px 6px;
        }
    }
}

@media screen and (width <=1600px) {
    .sectionswrapper {
        .secwrap {
            .secheader {
                padding: 15px 8px !important;
            }
        }
    }
}

@media screen and (width <=1400px) {

    .impostazioni-top {
        .impostazioni-button {
            transform: translateY(30px) !important;
        }
    }
}

@media screen and (width <=1000px) {
    .sectionswrapper {
        // flex-wrap: wrap !important;

        .entries {
            height: 60vh !important;
        }

        .secwrap {
            min-width: 40% !important;
        }
    }
}

@media screen and (width <=700px) {
    .sectionswrapper {
        .secwrap {
            min-width: 90% !important;
        }
    }

    .impostazioni-top {
        .impostazioni-button {
            transform: translateY(0px) !important;
        }
    }
}

@media screen and (width <=600px) {
    .topDash {
        align-items: flex-end !important;
        margin-bottom: 40px !important;

        #lasttopdashitem {
            margin-top: 20px !important;
        }

        #fstdiv {
            transform: translateY(10px) !important;
        }
    }

    #dashcerca {
        width: 100% !important;
    }

    #fstdashitem,
    #lasttopdashitem {
        width: 40% !important;
    }

    .MainDash {
        width: 95% !important;
        transform: translateX(-50%) !important;
    }

    .filtralead {
        .wrapperwrapper {
            .wrapper {
                flex-wrap: wrap !important;
                gap: 1rem !important;
            }
        }
    }

}

@media screen and (width <=450px) {
    .MainDash {
        width: 95% !important;
        transform: translateX(3%) !important;
    }
}
@media screen and (width >= 2400px){
    .table-main-mobile {
        width: 90% !important;
        margin-top: 40px;
    }
}
.filtralead2{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }