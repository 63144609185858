.messages {
    display: flex;
    margin: 20px 0;

    .text {
        padding: 15px 15px 30px 15px;
        width: 65%;
        position: relative;
        box-shadow: 0 10px 10px 2px rgba(0, 0, 0, 0.063);

        &::before {
            content: "";
            position: absolute;
            height: 30px;
            width: 40px;
        }
    }
}
.text{
    position: relative;
}
.text > span {
    position: absolute;
    bottom: 3px;
    right: 5px;
    font-size: 12px;
    color: #6C6C6C;
    padding: 4px;
}
.mymessage {
    justify-content: flex-end;

    .text {
        background-color: #E9F8DF;
        color: #30414C;
        border-radius: 15px 0px 15px 15px;
        font-size: 14px;

        span {
            color: #6C6C6C;
        }

        &::before {
            top: 0;
            right: -38px;
            background: #E9F8DF;
            background: linear-gradient(146deg, #E9F8DF 46%, rgba(255, 255, 255, 0) 46%);
        }

    }
}

.othermessage {
    justify-content: flex-start;

    .text {
        background-color: #F1F1F1;
        color: #30414C;
        border-radius: 0px 15px 15px 15px;
        font-size: 14px;

        &::before {
            top: 0;
            left: -40px;
            background: #F1F1F1;
            background: linear-gradient(210deg, #F1F1F1 46%, rgba(255, 255, 255, 0) 46%);            
        }
    }
}