.topDash {
    justify-content: flex-end;
}
.tab-dashboard-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0px;
    background-color: #F4F8FB;
    z-index: 10;
}
.tab-dashboard{
    padding: 6px 0;
    display: flex;
    width: 40%;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    margin-top: 20px;
    border-right: 1px solid black;
}
.filter-dashboard > button {
    border: none;
    background-color: #3471CC;
    color: white;
    cursor: pointer;
    padding: 6px 12px;
    border-radius: 5px;
    font-size: 14px;    
}
.tab-dashboard > button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 6px 12px;
    border-radius: 5px;
    font-size: 14px;
}
.filter-dashboard{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 15px;
    min-width: 50%;
}
.filter-data{
    display: flex;
    justify-content: center;
    align-items: center;
}
.filter-data label,
.filter-data input {
    font-size: 14px;
}
.tab-dashboard-top .filter-etichette select{
    font-size: 14px;
}
.filter-data input {
    border: none;
    outline: none;
    background-color: transparent;
    margin-left: 5px;
}
.tab-dashboard > .active-button{
    background-color: #3471CC;
    color: white;
}
.dashbody {
    transform: translateY(0px);
    margin: 20px 0;
    padding: 20px 0;
}
.lead-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
  }
  
  .lead-box {
    background-color: #f8f9fa;
    padding: 15px;
    height: 100px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
  }
  .total-lead-box {
    margin-bottom: 20px;
    width: 230px;
  }
  .lead-box h3 {
    margin: 0 0 0px;
    text-align: left;
    font-size: 14px;
    color: #000000ae;
  }
  .input-mese{
    width: 100px;
    margin: 0px 10px;
  }
  .loader-container{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .lead-average{
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 10px;
    background-color: #3471CC;
    color: #F4F8FB;
    padding: 4px 12px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .lead-box p {
    margin: 5px 0 0 0;
    font-size: 24px;
    font-weight: bold;
    text-align: left;
  }
  .lead-line {
    height: 4px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .line-chart-container{
    height: 400px;
    //background-color: white;
    border-radius: 10px;
    //box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 25px;
    padding-bottom: 30px;
    margin-bottom: 50px;
  }
  .line-chart-container > h4 {
    margin-bottom: 20px;
    font-size: 18px;
  }